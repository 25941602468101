exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archivio-news-js": () => import("./../../../src/pages/archivio-news.js" /* webpackChunkName: "component---src-pages-archivio-news-js" */),
  "component---src-pages-competence-center-aws-cloud-native-apps-js": () => import("./../../../src/pages/competence-center/aws/cloud-native-apps.js" /* webpackChunkName: "component---src-pages-competence-center-aws-cloud-native-apps-js" */),
  "component---src-pages-competence-center-aws-js": () => import("./../../../src/pages/competence-center/aws.js" /* webpackChunkName: "component---src-pages-competence-center-aws-js" */),
  "component---src-pages-competence-center-cloud-applications-js": () => import("./../../../src/pages/competence-center/cloud-applications.js" /* webpackChunkName: "component---src-pages-competence-center-cloud-applications-js" */),
  "component---src-pages-competence-center-drupal-js": () => import("./../../../src/pages/competence-center/drupal.js" /* webpackChunkName: "component---src-pages-competence-center-drupal-js" */),
  "component---src-pages-competence-center-js": () => import("./../../../src/pages/competence-center.js" /* webpackChunkName: "component---src-pages-competence-center-js" */),
  "component---src-pages-competence-center-liferay-js": () => import("./../../../src/pages/competence-center/liferay.js" /* webpackChunkName: "component---src-pages-competence-center-liferay-js" */),
  "component---src-pages-competence-center-liferay-liferay-commerce-js": () => import("./../../../src/pages/competence-center/liferay/liferay-commerce.js" /* webpackChunkName: "component---src-pages-competence-center-liferay-liferay-commerce-js" */),
  "component---src-pages-competence-center-liferay-liferay-dxp-js": () => import("./../../../src/pages/competence-center/liferay/liferay-dxp.js" /* webpackChunkName: "component---src-pages-competence-center-liferay-liferay-dxp-js" */),
  "component---src-pages-competence-center-microsoft-365-js": () => import("./../../../src/pages/competence-center/microsoft-365.js" /* webpackChunkName: "component---src-pages-competence-center-microsoft-365-js" */),
  "component---src-pages-competence-center-salesforce-js": () => import("./../../../src/pages/competence-center/salesforce.js" /* webpackChunkName: "component---src-pages-competence-center-salesforce-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-design-thinking-js": () => import("./../../../src/pages/design-thinking.js" /* webpackChunkName: "component---src-pages-design-thinking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-progetti-js": () => import("./../../../src/pages/progetti.js" /* webpackChunkName: "component---src-pages-progetti-js" */),
  "component---src-templates-notizia-js": () => import("./../../../src/templates/notizia.js" /* webpackChunkName: "component---src-templates-notizia-js" */)
}

